/* eslint-disable no-unused-vars */

<template>
  <div style="margin-left: 20px; margin-right: 20px">
    <a-table
      style="background-color: white"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="loading"
      rowKey="name"
    ></a-table>
    <!-- <a-button @click="createNewData">check</a-button> -->
  </div>
</template>

<script>
import axios from "axios";

const columns = [
  {
    title: "Диагноз",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Кол-во людей",
    dataIndex: "users",
    key: "users",
    // scopedSlots: { customRender: "created" },
    width: 130,
  },
];
export default {
  data() {
    return {
      loading: false,
      columns,
      data: [],
      names: [],
      diagnoses: [],
    };
  },
  created() {
    this.getStatistics();
    setTimeout(() => {
      if (this.diagnoses.length > 0 && this.names.length > 0) {
        this.createNewData();
      }
    }, 1000);
  },
  methods: {
    getStatistics() {
      this.loading = true;
      axios
        .get("cp/users/statistics/")
        .then((response) => {
          this.diagnoses = response.data.data.metrics.diagnoses;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("diagnoses/")
        .then((response) => {
          this.names = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createNewData() {
      this.data = this.diagnoses.map((diagnos) => {
        let nameInArray = this.names
          .map((e) => {
            if (e.id == diagnos[0]) return e.name;
            else return null;
          })
          .filter((e) => e != null)[0];
        if (!nameInArray) nameInArray = "Диагноз не указан";
        return { name: nameInArray, users: diagnos[1] };
      });
      if (this.data) this.data.sort((a, b) => (a.users > b.users ? -1 : 1));
      if (this.data) this.loading = false;
    },
  },
};
</script>
